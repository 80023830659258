.PageWrapperRoot {
  box-sizing: border-box;
  padding: 80px 40px 40px 40px;
  position: relative;
}

.PageWrapperHeader {
  width: 100%;
}

.PageWrapperContent {
  display: flex;
  column-gap: 20px;
}

.PageWrapperNavigation {
  min-width: 300px;
  width: 300px;
  height: 350px;
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 20px 0;
  border: 1px solid #212529;
}

.PageWrapperChildren {
  position: relative;
  flex: auto;
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #212529;
}

.PageWrapperNavigationLink {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  background: transparent;
  color: #212529;
  text-decoration: none;
  transition: all .2s;

  &:hover {
    color: white;
    background: #212529;
  }
}

.PageWrapperNavigationLink.active {
  display: block !important;
  width: 100% !important;
  box-sizing: border-box !important;
  padding: 10px 20px !important;
  color: white !important;
  background: #212529 !important;
  text-decoration: none !important;
}


.PageWrapperLogout {
  position: absolute;
  top: 20px;
  right: 40px;
}

.PageWrapperVersion {
  position: absolute;
  top: 20px;
  left: 40px;
}