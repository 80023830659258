.Item {
  border-radius: 0.375rem;
  border: 1px solid #212529;
}

.sortableDrag {
  background: white;
}

.tableRow {
  border-bottom: 1px solid #212529;
}
.tableHead {
  border-bottom: 1px solid #212529;
}