.Item {
  user-select: none;
  cursor: pointer;
  border: 1px solid #212529;
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 5px 5px 5px 10px;
  margin-bottom: 4px;
}
.sortableDrag {
  background: white;
}