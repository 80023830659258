.Root {
  max-width: 600px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketTag {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #212529;

  & > button {
    border-radius: 0.375rem;
    background: lightcoral;
  }
}