.Root {
  max-width: 600px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActiveTag {
  box-sizing: border-box;
  padding: 8px;
  border-radius: 0.375rem;
  border: 1px solid #212529;
  margin: 0 10px 0 0 ;
  & > button {
    margin-left: 8px;
    padding: 0 4px;
  }
}