.DropZoneRoot {
  border: 1px dashed #212529;
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > div > img {
    max-height: 300px;
  }
}

.DropZoneRootError {
  border: 1px dashed red;
  color: red;
  border-radius: 0.375rem;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > div > img {
    max-height: 300px;
  }
}