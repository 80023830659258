.NotificationRoot {
  position: absolute;
  max-height: 97vh;
  overflow: hidden;
  top: 20px;
  right: 20px;
  z-index: 6969;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 1px;
}