.Root {
  max-width: 600px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DatePikerWrapper {
  width: 100%;
}

.DatePikerInput {
  width: 100%;
  display: flex;
  flex: auto;
  border-radius: 0 0.375rem 0.375rem 0;
  box-sizing: border-box;
  padding: 0 0 0 12px;
  height: 38px;
  border: 1px solid #dee2e6;
}