// helpcss: marginTopClass
.marginTop10 {
  margin-top: 10px;
}
.marginTop20 {
  margin-top: 20px;
}
.marginTop40 {
  margin-top: 40px;
}
.marginTop60 {
  margin-top: 60px;
}

// helpcss: marginBottomClass
.marginBottom10 {
  margin-bottom: 10px;
}
.marginBottom20 {
  margin-bottom: 20px;
}
.marginBottom40 {
  margin-bottom: 40px;
}
.marginBottom60 {
  margin-bottom: 60px;
}

// helpcss: marginRightClass
.marginRight10 {
  margin-right: 10px;
}
.marginRight20 {
  margin-right: 20px;
}
.marginRight40 {
  margin-right: 40px;
}
.marginRight60 {
  margin-right: 60px;
}

// helpcss: marginLeftClass
.marginLeft10 {
  margin-left: 10px;
}
.marginLeft20 {
  margin-left: 20px;
}
.marginLeft40 {
  margin-left: 40px;
}
.marginLeft60 {
  margin-left: 60px;
}

// helpcss: flexbox
.flex {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-space-between {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
}

// helpcss: block
.block {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #212529;
  border-radius: 0.375rem;
}

// helpcss: grids
.grid2x {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  box-sizing: border-box;
}
.grid3x {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  box-sizing: border-box;
}
.grid4x {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  box-sizing: border-box;
}

.grid5x {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}
.grid6x {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}
.grid7x {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}
.grid8x {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}