.chatsListItem {
  cursor: pointer;
  background: transparent;
  padding: 10px;
  border-top: 1px solid #212529;
  border-bottom: 1px solid #212529;
  transition: all .2s;
  margin-bottom: 10px;

  &:hover {
    background: lightgrey;
  }

  &Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & > p {
      font-size: 17px;
      font-weight: 500;
    }
  }

  &Content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      font-size: 13px;
      opacity: .7;
    }
  }
}

.chatsListItemActive {
  background: lightgrey;
}