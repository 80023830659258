.chatsRoot {
  height: 84vh;
}

.chatsList {
  width: 400px;
  height: 88%;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  border-radius: 0.375rem;
  border: 1px solid #212529;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
    position: absolute;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}